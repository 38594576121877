// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-products-biodegrade-arecaleaf-mdx": () => import("./../../src/content/products/biodegrade/arecaleaf.mdx" /* webpackChunkName: "component---src-content-products-biodegrade-arecaleaf-mdx" */),
  "component---src-content-products-biodegrade-bananaleaf-mdx": () => import("./../../src/content/products/biodegrade/bananaleaf.mdx" /* webpackChunkName: "component---src-content-products-biodegrade-bananaleaf-mdx" */),
  "component---src-content-products-flowers-anthurium-anthurium-mdx": () => import("./../../src/content/products/flowers/anthurium/anthurium.mdx" /* webpackChunkName: "component---src-content-products-flowers-anthurium-anthurium-mdx" */),
  "component---src-content-products-flowers-carnation-carnation-mdx": () => import("./../../src/content/products/flowers/carnation/carnation.mdx" /* webpackChunkName: "component---src-content-products-flowers-carnation-carnation-mdx" */),
  "component---src-content-products-flowers-chrysanthemum-chrysanthemum-mdx": () => import("./../../src/content/products/flowers/chrysanthemum/chrysanthemum.mdx" /* webpackChunkName: "component---src-content-products-flowers-chrysanthemum-chrysanthemum-mdx" */),
  "component---src-content-products-flowers-gerbera-gerbera-mdx": () => import("./../../src/content/products/flowers/gerbera/gerbera.mdx" /* webpackChunkName: "component---src-content-products-flowers-gerbera-gerbera-mdx" */),
  "component---src-content-products-flowers-gladiolus-gladiolus-mdx": () => import("./../../src/content/products/flowers/gladiolus/gladiolus.mdx" /* webpackChunkName: "component---src-content-products-flowers-gladiolus-gladiolus-mdx" */),
  "component---src-content-products-flowers-jasmine-jasmine-mdx": () => import("./../../src/content/products/flowers/jasmine/jasmine.mdx" /* webpackChunkName: "component---src-content-products-flowers-jasmine-jasmine-mdx" */),
  "component---src-content-products-flowers-lily-lily-mdx": () => import("./../../src/content/products/flowers/lily/lily.mdx" /* webpackChunkName: "component---src-content-products-flowers-lily-lily-mdx" */),
  "component---src-content-products-flowers-lotus-lotus-mdx": () => import("./../../src/content/products/flowers/lotus/lotus.mdx" /* webpackChunkName: "component---src-content-products-flowers-lotus-lotus-mdx" */),
  "component---src-content-products-flowers-marigold-marigold-mdx": () => import("./../../src/content/products/flowers/marigold/marigold.mdx" /* webpackChunkName: "component---src-content-products-flowers-marigold-marigold-mdx" */),
  "component---src-content-products-flowers-orchids-orchids-mdx": () => import("./../../src/content/products/flowers/orchids/orchids.mdx" /* webpackChunkName: "component---src-content-products-flowers-orchids-orchids-mdx" */),
  "component---src-content-products-flowers-rose-rose-mdx": () => import("./../../src/content/products/flowers/rose/rose.mdx" /* webpackChunkName: "component---src-content-products-flowers-rose-rose-mdx" */),
  "component---src-content-products-flowers-tuberose-tuberose-mdx": () => import("./../../src/content/products/flowers/tuberose/tuberose.mdx" /* webpackChunkName: "component---src-content-products-flowers-tuberose-tuberose-mdx" */),
  "component---src-content-products-fruits-banana-banana-mdx": () => import("./../../src/content/products/fruits/banana/banana.mdx" /* webpackChunkName: "component---src-content-products-fruits-banana-banana-mdx" */),
  "component---src-content-products-fruits-guava-guava-mdx": () => import("./../../src/content/products/fruits/guava/guava.mdx" /* webpackChunkName: "component---src-content-products-fruits-guava-guava-mdx" */),
  "component---src-content-products-fruits-lemon-lemon-mdx": () => import("./../../src/content/products/fruits/lemon/lemon.mdx" /* webpackChunkName: "component---src-content-products-fruits-lemon-lemon-mdx" */),
  "component---src-content-products-fruits-mango-mango-mdx": () => import("./../../src/content/products/fruits/mango/mango.mdx" /* webpackChunkName: "component---src-content-products-fruits-mango-mango-mdx" */),
  "component---src-content-products-fruits-muskmelon-muskmelon-mdx": () => import("./../../src/content/products/fruits/muskmelon/muskmelon.mdx" /* webpackChunkName: "component---src-content-products-fruits-muskmelon-muskmelon-mdx" */),
  "component---src-content-products-fruits-papaya-papaya-mdx": () => import("./../../src/content/products/fruits/papaya/papaya.mdx" /* webpackChunkName: "component---src-content-products-fruits-papaya-papaya-mdx" */),
  "component---src-content-products-fruits-sapota-sapota-mdx": () => import("./../../src/content/products/fruits/sapota/sapota.mdx" /* webpackChunkName: "component---src-content-products-fruits-sapota-sapota-mdx" */),
  "component---src-content-products-fruits-watermelon-watermelon-mdx": () => import("./../../src/content/products/fruits/watermelon/watermelon.mdx" /* webpackChunkName: "component---src-content-products-fruits-watermelon-watermelon-mdx" */),
  "component---src-content-products-plantationcrop-coconut-coconut-mdx": () => import("./../../src/content/products/plantationcrop/coconut/coconut.mdx" /* webpackChunkName: "component---src-content-products-plantationcrop-coconut-coconut-mdx" */),
  "component---src-content-products-plantationcrop-coffee-coffee-mdx": () => import("./../../src/content/products/plantationcrop/coffee/coffee.mdx" /* webpackChunkName: "component---src-content-products-plantationcrop-coffee-coffee-mdx" */),
  "component---src-content-products-plants-aquaticplants-aquaticplants-mdx": () => import("./../../src/content/products/plants/aquaticplants/aquaticplants.mdx" /* webpackChunkName: "component---src-content-products-plants-aquaticplants-aquaticplants-mdx" */),
  "component---src-content-products-plants-bamboo-bamboo-mdx": () => import("./../../src/content/products/plants/bamboo/bamboo.mdx" /* webpackChunkName: "component---src-content-products-plants-bamboo-bamboo-mdx" */),
  "component---src-content-products-plants-cacti-succulents-cacti-succulents-mdx": () => import("./../../src/content/products/plants/cacti&succulents/cacti&succulents.mdx" /* webpackChunkName: "component---src-content-products-plants-cacti-succulents-cacti-succulents-mdx" */),
  "component---src-content-products-plants-climberscreeper-vines-climberscreeper-vines-mdx": () => import("./../../src/content/products/plants/climberscreeper&vines/climberscreeper&vines.mdx" /* webpackChunkName: "component---src-content-products-plants-climberscreeper-vines-climberscreeper-vines-mdx" */),
  "component---src-content-products-plants-ferns-ferns-mdx": () => import("./../../src/content/products/plants/Ferns/ferns.mdx" /* webpackChunkName: "component---src-content-products-plants-ferns-ferns-mdx" */),
  "component---src-content-products-plants-flower-plants-indoor-flower-plants-indoor-mdx": () => import("./../../src/content/products/plants/FlowerPlantsIndoor/FlowerPlantsIndoor.mdx" /* webpackChunkName: "component---src-content-products-plants-flower-plants-indoor-flower-plants-indoor-mdx" */),
  "component---src-content-products-plants-flower-plants-outdoor-flower-plants-outdoor-mdx": () => import("./../../src/content/products/plants/FlowerPlantsOutdoor/FlowerPlantsOutdoor.mdx" /* webpackChunkName: "component---src-content-products-plants-flower-plants-outdoor-flower-plants-outdoor-mdx" */),
  "component---src-content-products-plants-fruit-plant-fruit-plant-mdx": () => import("./../../src/content/products/plants/FruitPlant/Fruit_Plant.mdx" /* webpackChunkName: "component---src-content-products-plants-fruit-plant-fruit-plant-mdx" */),
  "component---src-content-products-plants-indoor-plant-indoor-plant-mdx": () => import("./../../src/content/products/plants/Indoor_Plant/Indoor_Plant.mdx" /* webpackChunkName: "component---src-content-products-plants-indoor-plant-indoor-plant-mdx" */),
  "component---src-content-products-plants-lilies-bulbous-plants-lilies-bulbousplants-mdx": () => import("./../../src/content/products/plants/Lilies&BulbousPlants/lilies&bulbousplants.mdx" /* webpackChunkName: "component---src-content-products-plants-lilies-bulbous-plants-lilies-bulbousplants-mdx" */),
  "component---src-content-products-plants-medicinalplants-medicinalplants-mdx": () => import("./../../src/content/products/plants/Medicinalplants/Medicinalplants.mdx" /* webpackChunkName: "component---src-content-products-plants-medicinalplants-medicinalplants-mdx" */),
  "component---src-content-products-plants-orchids-orchids-mdx": () => import("./../../src/content/products/plants/Orchids/Orchids.mdx" /* webpackChunkName: "component---src-content-products-plants-orchids-orchids-mdx" */),
  "component---src-content-products-plants-ornamental-shrubs-ornamental-shrubs-mdx": () => import("./../../src/content/products/plants/OrnamentalShrubs/Ornamental_Shrubs.mdx" /* webpackChunkName: "component---src-content-products-plants-ornamental-shrubs-ornamental-shrubs-mdx" */),
  "component---src-content-products-plants-palms-cycads-palms-cycads-mdx": () => import("./../../src/content/products/plants/PalmsCycads/PalmsCycads.mdx" /* webpackChunkName: "component---src-content-products-plants-palms-cycads-palms-cycads-mdx" */),
  "component---src-content-products-plants-spice-plants-spice-plants-mdx": () => import("./../../src/content/products/plants/Spice_Plants/SpicePlants.mdx" /* webpackChunkName: "component---src-content-products-plants-spice-plants-spice-plants-mdx" */),
  "component---src-content-products-plants-trees-trees-mdx": () => import("./../../src/content/products/plants/Trees/Trees.mdx" /* webpackChunkName: "component---src-content-products-plants-trees-trees-mdx" */),
  "component---src-content-products-rice-basmathi-mdx": () => import("./../../src/content/products/rice/basmathi.mdx" /* webpackChunkName: "component---src-content-products-rice-basmathi-mdx" */),
  "component---src-content-products-rice-indianrice-mdx": () => import("./../../src/content/products/rice/indianrice.mdx" /* webpackChunkName: "component---src-content-products-rice-indianrice-mdx" */),
  "component---src-content-products-spices-blackperpper-blackpepper-mdx": () => import("./../../src/content/products/spices/blackperpper/blackpepper.mdx" /* webpackChunkName: "component---src-content-products-spices-blackperpper-blackpepper-mdx" */),
  "component---src-content-products-spices-cardamom-cardamom-mdx": () => import("./../../src/content/products/spices/cardamom/cardamom.mdx" /* webpackChunkName: "component---src-content-products-spices-cardamom-cardamom-mdx" */),
  "component---src-content-products-spices-cinnamon-cinnamon-mdx": () => import("./../../src/content/products/spices/cinnamon/cinnamon.mdx" /* webpackChunkName: "component---src-content-products-spices-cinnamon-cinnamon-mdx" */),
  "component---src-content-products-spices-cloves-cloves-mdx": () => import("./../../src/content/products/spices/cloves/cloves.mdx" /* webpackChunkName: "component---src-content-products-spices-cloves-cloves-mdx" */),
  "component---src-content-products-spices-corianderseeds-corianderseeds-mdx": () => import("./../../src/content/products/spices/corianderseeds/corianderseeds.mdx" /* webpackChunkName: "component---src-content-products-spices-corianderseeds-corianderseeds-mdx" */),
  "component---src-content-products-spices-cuminseeds-cuminseeds-mdx": () => import("./../../src/content/products/spices/cuminseeds/cuminseeds.mdx" /* webpackChunkName: "component---src-content-products-spices-cuminseeds-cuminseeds-mdx" */),
  "component---src-content-products-spices-drychilli-dryredchilli-mdx": () => import("./../../src/content/products/spices/drychilli/dryredchilli.mdx" /* webpackChunkName: "component---src-content-products-spices-drychilli-dryredchilli-mdx" */),
  "component---src-content-products-spices-dryginger-dryginger-mdx": () => import("./../../src/content/products/spices/dryginger/dryginger.mdx" /* webpackChunkName: "component---src-content-products-spices-dryginger-dryginger-mdx" */),
  "component---src-content-products-spices-nutmeg-nutmeg-mdx": () => import("./../../src/content/products/spices/nutmeg/nutmeg.mdx" /* webpackChunkName: "component---src-content-products-spices-nutmeg-nutmeg-mdx" */),
  "component---src-content-products-spices-tamarind-tamarind-mdx": () => import("./../../src/content/products/spices/tamarind/tamarind.mdx" /* webpackChunkName: "component---src-content-products-spices-tamarind-tamarind-mdx" */),
  "component---src-content-products-spices-turmeric-turmeric-mdx": () => import("./../../src/content/products/spices/turmeric/turmeric.mdx" /* webpackChunkName: "component---src-content-products-spices-turmeric-turmeric-mdx" */),
  "component---src-content-products-veg-exotics-exotics-mdx": () => import("./../../src/content/products/veg/exotics/exotics.mdx" /* webpackChunkName: "component---src-content-products-veg-exotics-exotics-mdx" */),
  "component---src-content-products-veg-exotics-productlist-sub-js": () => import("./../../src/content/products/veg/exotics/productlistSub.js" /* webpackChunkName: "component---src-content-products-veg-exotics-productlist-sub-js" */),
  "component---src-content-products-veg-garlic-garlic-mdx": () => import("./../../src/content/products/veg/garlic/garlic.mdx" /* webpackChunkName: "component---src-content-products-veg-garlic-garlic-mdx" */),
  "component---src-content-products-veg-ginger-ginger-mdx": () => import("./../../src/content/products/veg/ginger/ginger.mdx" /* webpackChunkName: "component---src-content-products-veg-ginger-ginger-mdx" */),
  "component---src-content-products-veg-greens-greens-mdx": () => import("./../../src/content/products/veg/greens/greens.mdx" /* webpackChunkName: "component---src-content-products-veg-greens-greens-mdx" */),
  "component---src-content-products-veg-greens-productlist-sub-js": () => import("./../../src/content/products/veg/greens/productlistSub.js" /* webpackChunkName: "component---src-content-products-veg-greens-productlist-sub-js" */),
  "component---src-content-products-veg-natives-natives-mdx": () => import("./../../src/content/products/veg/natives/natives.mdx" /* webpackChunkName: "component---src-content-products-veg-natives-natives-mdx" */),
  "component---src-content-products-veg-natives-productlist-sub-js": () => import("./../../src/content/products/veg/natives/productlistSub.js" /* webpackChunkName: "component---src-content-products-veg-natives-productlist-sub-js" */),
  "component---src-content-products-veg-onions-onions-mdx": () => import("./../../src/content/products/veg/onions/onions.mdx" /* webpackChunkName: "component---src-content-products-veg-onions-onions-mdx" */),
  "component---src-content-products-veg-onions-productlist-sub-js": () => import("./../../src/content/products/veg/onions/productlistSub.js" /* webpackChunkName: "component---src-content-products-veg-onions-productlist-sub-js" */),
  "component---src-content-products-veg-potato-potato-mdx": () => import("./../../src/content/products/veg/potato/potato.mdx" /* webpackChunkName: "component---src-content-products-veg-potato-potato-mdx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-category-biodegradable-js": () => import("./../../src/pages/category/biodegradable.js" /* webpackChunkName: "component---src-pages-category-biodegradable-js" */),
  "component---src-pages-category-crops-js": () => import("./../../src/pages/category/crops.js" /* webpackChunkName: "component---src-pages-category-crops-js" */),
  "component---src-pages-category-flower-js": () => import("./../../src/pages/category/flower.js" /* webpackChunkName: "component---src-pages-category-flower-js" */),
  "component---src-pages-category-fruits-js": () => import("./../../src/pages/category/fruits.js" /* webpackChunkName: "component---src-pages-category-fruits-js" */),
  "component---src-pages-category-page-js": () => import("./../../src/pages/category_page.js" /* webpackChunkName: "component---src-pages-category-page-js" */),
  "component---src-pages-category-rice-js": () => import("./../../src/pages/category/rice.js" /* webpackChunkName: "component---src-pages-category-rice-js" */),
  "component---src-pages-category-spices-js": () => import("./../../src/pages/category/spices.js" /* webpackChunkName: "component---src-pages-category-spices-js" */),
  "component---src-pages-category-veg-js": () => import("./../../src/pages/category/veg.js" /* webpackChunkName: "component---src-pages-category-veg-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-horticulture-js": () => import("./../../src/pages/horticulture.js" /* webpackChunkName: "component---src-pages-horticulture-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

