import Head from "../../../../src/components/head";
import { Container, Wrapper } from "../../../../src/components/layout/element";
import Productinfopage from "../../../../src/components/productinfopage/productinfopage";
import { FlowChartImg } from "../../../../src/components/productinfopage/productinfopage";
import AvailabilityChart from "../../../../src/content/products/veg/natives/Availablity-chart-1.png";
import FlowChart from "../../../../src/content/products/veg/natives/flowchart-native.png";
import ProductlistSub from "../../../../src/content/products/veg/natives/productlistSub.js";
import * as React from 'react';
export default {
  Head,
  Container,
  Wrapper,
  Productinfopage,
  FlowChartImg,
  AvailabilityChart,
  FlowChart,
  ProductlistSub,
  React
};